// variables and mixins
@import "payva-dashboard-scss/variables";
@import "payva-dashboard-scss/mixins";
@import "payva-dashboard-scss/shadows";

// plugin css
@import "payva-dashboard-scss/plugins/plugin-nouislider";
@import "payva-dashboard-scss/plugins/plugin-perfect-scrollbar";
@import "payva-dashboard-scss/plugins/plugin-react-datetime";
@import "payva-dashboard-scss/plugins/plugin-react-bootstrap-sweetalert";
@import "payva-dashboard-scss/plugins/plugin-react-chartist";
@import "payva-dashboard-scss/plugins/plugin-react-big-calendar";
@import "payva-dashboard-scss/plugins/plugin-react-jvectormap";
@import "payva-dashboard-scss/plugins/plugin-react-table";
@import "payva-dashboard-scss/plugins/plugin-react-tagsinput";

// Core CSS
@import "payva-dashboard-scss/misc";
@import "payva-dashboard-scss/fileupload";
@import "payva-dashboard-scss/fixed-plugin";
